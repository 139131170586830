import moment from "moment";
import "moment-timezone";
//formats the date according to monday format
export const formatDate = (date) => {
  const now = moment();
  const givenDate = moment(date);

  // Check if the year of the given date is less than the current year
  if (givenDate.year() < now.year()) {
    return givenDate.format("MMM D, YYYY"); // e.g. "Mar 27 2023"
  } else {
    return givenDate.format("MMM D"); // e.g. "Mar 27"
  }
};

export const formatTime = (date) => {
  const originalDate = moment.utc(date);

  const userTimezone = moment.tz.guess();
  const convertedDate = originalDate.tz(userTimezone);

  // Format the date in the desired format
  const fromNow = convertedDate.fromNow();

  return fromNow;
};

export const tooltipDate = (date) => {
  const originalDate = moment.utc(date);

  const userTimezone = moment.tz.guess();
  const convertedDate = originalDate.tz(userTimezone);

  // Format the date in the desired format
  const formattedDate = convertedDate.format("MMM D, YYYY, hh:mm A");

  return formattedDate;
};
