import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const uri = process.env.REACT_APP_URI;

// Async thunk to fetch access token from backend
export const fetchAccessToken = createAsyncThunk(
  "accessToken/fetchToken",
  async (userId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("x-access-token");

      if (token && token !== "null") return token;

      const response = await axios.get(`${uri}/auth/access_token`, {
        headers: {
          x_unix: userId,
        },
      });

      const accessToken = response.data?.data?.access_token;
      localStorage.setItem("x-access-token", accessToken); // Store token in localStorage
      return accessToken;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch access token from backend
export const refreshToken = createAsyncThunk(
  "accessToken/refreshToken",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${uri}/auth/access_token`, {
        headers: {
          x_unix: userId,
        },
      });

      const accessToken = response.data?.data?.access_token;
      localStorage.setItem("x-access-token", accessToken); // Store token in localStorage
      return accessToken;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const accessTokenSlice = createSlice({
  name: "accessToken",
  initialState: {
    token: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccessToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(fetchAccessToken.rejected, (state) => {
        state.loading = false;
        state.token = null;
      })
      .addCase(refreshToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.loading = false;
        state.token = null;
      });
  },
});

export default accessTokenSlice.reducer;
