import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
} from "monday-ui-react-core";
import { useSlug } from "../../hooks/context/useSlug";
import useUser from "../../hooks/context/userHook";
import ErrorPage from "../errorPage";
import CustomPagination from "../Pagination"; // Import the pagination component
import UsageTableRow from "./UsageTableRow";
import useTokenHook from "../../hooks/context/useTokenHook";

const uri = process.env.REACT_APP_URI;

const DetailedUsageTable = () => {
  const { userId, accountId } = useUser();
  const { slug } = useSlug();
  const { token } = useTokenHook();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleToggle = (boardId) => {
    setExpandedRows((current) => {
      if (current.includes(boardId)) {
        return current.filter((id) => id !== boardId);
      } else {
        return [...current, boardId];
      }
    });
  };

  const fetchSyncData = async () => {
    try {
      setError(false);
      setLoading(true);
      const response = await axios.get(`${uri}/simb/usage/detailed-table`, {
        headers: {
          Authorization: `Bearer ${token}`,
          // userid: userId,
          // accountId: accountId,
        },
        timeout: 15000,
      });

      const activeBoards = response.data.activeBoards;

      if (activeBoards) {
        const tableSource = activeBoards.map((board) => {
          return {
            key: board.boardId,
            boardId: board.boardId,
            boardName: board.boardName,
            itemsCount: board.items.length,
            items: board.items,
          };
        });

        setTableData(tableSource);
      }
    } catch (error) {
      console.error(error.message);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId && accountId) {
      fetchSyncData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, accountId]);

  if (error) {
    return <ErrorPage revalidate={fetchSyncData} />;
  }

  const columns = [
    {
      id: "boards",
      loadingStateType: "medium-text",
      title: "Boards",
      width: 375,
      sort: true,
    },
    {
      id: "synced_items",
      loadingStateType: "medium-text",
      title: "Synced Items",
      width: {
        max: 200,
        min: 150,
      },
      sort: true,
    },
  ];

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  return (
    <div>
      <div className="flex flex-col items-start">
        <h3 className="font-semibold">Overview</h3>
        <div className="mt-4 ">
          <div className="flex justify-end mb-2 text-sm">
            {Math.min(indexOfLastItem, tableData.length)} out of{" "}
            {tableData.length} boards
          </div>
          <Table
            dataState={{
              isLoading: loading,
            }}
            columns={columns}
            className="w-auto max-h-[444px]"
          >
            <TableHeader>
              {columns.map((headerCell, index) => (
                <TableHeaderCell key={index + 1} title={headerCell.title} />
              ))}
            </TableHeader>
            <TableBody className="w-full">
              {currentItems.map((board, index) => {
                const isVisible = expandedRows.includes(board.boardId);
                return (
                  <UsageTableRow
                    index={index}
                    boardId={board.boardId}
                    items={board.items}
                    slug={slug}
                    isVisible={isVisible}
                    handleToggle={handleToggle}
                  />
                );
              })}
            </TableBody>
          </Table>
          {/* Conditionally render pagination if there is more than 1 page */}
          {totalPages > 1 && (
            <div className="flex justify-center mt-4">
              <CustomPagination
                pageCount={totalPages}
                currentPage={page}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailedUsageTable;
