import {
  Person,
  Status,
  Counter,
  Calendar,
  Email,
  Mobile,
  Dropdown,
  Text,
  Favorite,
  Link,
  LongText,
  Timeline,
  File,
  Checkbox,
  Board,
  Location,
} from "monday-ui-react-core/icons";

export const supportedColumns = [
  "person",
  "status",
  "numbers",
  "date",
  "email",
  "phone",
  "dropdown",
  "text",
  "rating",
  "link",
  "long_text",
  "timeline",
  "people",
  "file",
  "checkbox",
];

export const columnsIcons = [
  { type: "person", icon: Person },
  { type: "status", icon: Status },
  { type: "numbers", icon: Counter },
  { type: "date", icon: Calendar },
  { type: "email", icon: Email },
  { type: "phone", icon: Mobile },
  { type: "dropdown", icon: Dropdown },
  { type: "text", icon: Text },
  { type: "rating", icon: Favorite },
  { type: "link", icon: Link },
  { type: "long_text", icon: LongText },
  { type: "timeline", icon: Timeline },
  { type: "people", icon: Person },
  { type: "file", icon: File },
  { type: "checkbox", icon: Checkbox },
  { type: "board_relation", icon: Board },
  { type: "location", icon: Location },
];
