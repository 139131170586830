import React from "react";
import { Loader } from "monday-ui-react-core";

const Spinner = ({ size = Loader.sizes.SMALL, className }: any) => {
  return (
    <Loader className={className} size={size} color={Loader.colors.PRIMARY} />
  );
};

export default Spinner;
