export const generateWorkspaceUrl = (slug, workspaceId) => {
  return `https://${slug}.monday.com/workspaces/${workspaceId}`;
};

export const generateBoardUrl = (slug, boardId) => {
  return `https://${slug}.monday.com/boards/${boardId}`;
};

export const generateItemUrl = (slug, boardId, itemId) => {
  return `https://${slug}.monday.com/boards/${boardId}/pulses/${itemId}`;
};
