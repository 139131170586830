import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DetailedUsageTable from "../components/Usage/DetailedUsageTable";
import { Space } from "antd";
import { MoveArrowLeft } from "monday-ui-react-core/icons";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

const DetailedUsageReport = () => {
  const [userId, setUserId] = useState();
  const [accountId, setAccountId] = useState();
  const [slug, setSlug] = useState();

  useEffect(() => {
    monday
      .get("context")
      .then((res) => {
        setUserId(res.data.user.id);
        setAccountId(res.data.account.id);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userId, accountId]);

  useEffect(() => {
    monday
      .api(
        `query {
    users {
      account {
        id
        show_timeline_weekends
        tier 
        slug
        plan {
          period
        }
      }
    }
  }`,
        { apiVersion: "2023-10" }
      )
      .then((res) => {
        if (res.data.users[0].account.slug) {
          setSlug(res.data.users[0].account.slug);
        }
      });
  }, [slug]);

  return (
    <div className="h-screen px-6 pt-8 pb-4">
      <Space direction="vertical" className="flex items-start w-full">
        <div>
          <Link to="/">
            <MoveArrowLeft />
          </Link>
        </div>
        <DetailedUsageTable userId={userId} accountId={accountId} slug={slug} />
      </Space>
    </div>
  );
};

export default DetailedUsageReport;
