import { useEffect } from "react";

const useBreadcrumbBarResize = (locationColumnRef) => {
  useEffect(() => {
    const handleResize = () => {
      const breadcrumbs = document.querySelectorAll(".breadcrumb-locations");

      if (breadcrumbs) {
        breadcrumbs.forEach((breadcrumb) => {
          if (locationColumnRef) {
            const computedStyle = getComputedStyle(locationColumnRef);
            const width =
              locationColumnRef.offsetWidth -
              parseFloat(computedStyle.paddingLeft) -
              parseFloat(computedStyle.paddingRight);

            const olElement = breadcrumb.querySelector("nav > ol");

            olElement.style.maxWidth = `${width}px`;
          }
        });
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize once initially
    if (locationColumnRef) {
      handleResize();
    }

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [locationColumnRef]);
};

export default useBreadcrumbBarResize;
