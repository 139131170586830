import React, { useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "monday-ui-react-core/dist/main.css";
import UpdateVersion from "../pages/update/new_version";
import ErrorPage from "../components/errorPage";
import PageLoader from "../components/PageLoader";
import useBoardAndItems from "../hooks/context/useBoardAndItems";
import useUser from "../hooks/context/userHook";
import AddItemDropdown from "../components/addItem/AddItemDropdown";
import { MondayApiQueryInstance } from "../services/monday/monday-service-ts";

const monday = mondaySdk();
const uri = process.env.REACT_APP_URI;

const BatchItemMenu = () => {
  const { userId } = useUser();
  const { itemIds } = useBoardAndItems();
  const [error, setError] = useState(false);
  const [userAuth, setUserAuth] = useState(false);
  const [fetchAuth, setFetchAuth] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [version, setVersion] = useState(false);
  const [bigAccount, setBigAccount] = useState(true);

  const navigate = useNavigate();
  monday.execute("valueCreatedForUser");

  useEffect(() => {
    const fetchAccountSize = async () => {
      try {
        const checkAccountSize = localStorage.getItem("isBigAccount");

        if (checkAccountSize !== null) {
          setBigAccount(JSON.parse(checkAccountSize));
        } else {
          const boardSize = await MondayApiQueryInstance.getAccountSize();

          const isBigAccount = boardSize.length >= 500;

          localStorage.setItem("isBigAccount", JSON.stringify(isBigAccount));
          setBigAccount(isBigAccount);
        }
      } catch (error) {
        throw error;
      }
    };

    fetchAccountSize();
  }, []);

  useEffect(() => {
    if (fetchAuth) {
      function actionToExecute() {
        //replace the url
        axios
          .post(`${uri}/simb/check-userid-onboarding`, { userid: userId })
          .then((res) => {
            if (!res.data.error) {
              if (res.data.data) {
                setUserAuth(true);
                clearInterval(intervalId);
              }
            }
          })
          .catch((error) => {
            console.log("error => ", error);
          });
      }
      const intervalId = setInterval(actionToExecute, 1000);
      return () => clearInterval(intervalId);
    }
  }, [userId, fetchAuth]);

  useEffect(() => {
    monday.execute("valueCreatedForUser");
    monday.get("context").then((res) => {
      let dataVersion = res.data.appVersion.versionData.major;
      //version for force update

      //make the app true
      parseInt(dataVersion) < 27 ? setVersion(false) : setVersion(false);
      res.data.user.isViewOnly ? setIsViewOnly(true) : setIsViewOnly(false);
      let itemidsArray = [];
      res.data.appFeature.type === "AppFeatureItemBatchAction"
        ? (itemidsArray = res.data.selectedPulsesIds)
        : itemidsArray.push(res.data.itemId);
    });
  }, []);

  useEffect(() => {
    if (userId) {
      setFetchAuth(false);
      //replace the url
      axios
        .post(`${uri}/simb/check-userid-onboarding`, { userid: userId })
        .then((res) => {
          if (!res.data.error) {
            if (res.data.data) {
              setUserAuth(true);
            } else {
              navigate("/onboarding");
            }
          } else {
            setError(true);
          }
        })
        .catch((error) => {
          console.log("error => ", error);
          setError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div>
      {isViewOnly ? (
        <div className="flex items-center justify-center h-screen">
          <div className="px-4 py-3 bg-red-100 rounded">
            <span className="block sm:inline">
              As a viewer, you are unable to use the SIMB.
            </span>
          </div>
        </div>
      ) : !error ? (
        itemIds && userAuth ? (
          version ? (
            <UpdateVersion />
          ) : (
            <div className="w-full h-screen">
              <AddItemDropdown
                centered={false}
                fullWidth={true}
                bigAccount={bigAccount}
              />
            </div>
          )
        ) : (
          <div className="App">
            <PageLoader />
          </div>
        )
      ) : (
        <ErrorPage />
      )}
    </div>
  );
};

export default BatchItemMenu;
