//@ts-nocheck
import React from "react";
import { Switch } from "antd";
import { Tooltip } from "monday-ui-react-core";
import { Info } from "monday-ui-react-core/icons";
import { SettingsSwitchPropsInterface } from "./types/settings-drawer-interface";

export const SettingSwitch = ({
  label,
  initialValue,
  setNewValue,
  settingKey,
  updateFunction,
  tooltip,
}: SettingsSwitchPropsInterface) => {
  const handleSwitch = async (checked: boolean) => {
    const newValue = checked;

    setNewValue((prevSettings) => ({
      ...prevSettings,
      [settingKey]: newValue,
    }));

    try {
      await updateFunction(newValue);
    } catch (err) {
      console.error(`Failed to update ${label.toLowerCase()}:`, err);

      setValue(!newValue); // Revert change on error
    } finally {
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-row gap-1">
        <p className="text-sm">{label}</p>
        <Tooltip zIndex={1210} content={tooltip}>
          <Info />
        </Tooltip>
      </div>
      <Switch
        checked={initialValue}
        onChange={(checked) => {
          handleSwitch(checked);
        }}
      />
    </div>
  );
};
