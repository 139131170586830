// getColumnIcon.js
import { columnsIcons } from "../data/constants/supportedColumns";

//provides icons to proper columns in Item Locations Table
const getColumnIcon = (type) => {
  const column = columnsIcons.find((c) => c.type === type);

  return column?.icon || null;
};

export default getColumnIcon;
