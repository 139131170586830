import { useState } from "react";
import useSWR from "swr";
import useUser from "../context/userHook";
import useBoardAndItems from "../context/useBoardAndItems";
import { getBoardsList } from "../../utils/requests/boardsRequest";
import { useNavigate } from "react-router-dom";
import useTokenHook from "../context/useTokenHook";

export const useBoardsList = (workspaceId: number) => {
  const [success, setSuccess] = useState(false);
  const { userId } = useUser();
  const { currentBoard, itemIds } = useBoardAndItems();
  const { token } = useTokenHook();

  const navigate = useNavigate();

  const { isLoading, error, data, isValidating, mutate } = useSWR(
    userId ? `${workspaceId}:boards_list:${userId}` : null,
    () => getBoardsList(token, itemIds, currentBoard, workspaceId),
    {
      revalidateOnMount: true,
      onSuccess: () => {
        setSuccess(true);
      },
      onError: (error) => {
        setSuccess(false);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message === "User not found"
        ) {
          navigate("/onboarding");
        }
      },
      onErrorRetry: (error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message === "User not found"
        ) {
          navigate("/onboarding");
        }
      },
    }
  );

  return {
    isLoading,
    error,
    isValidating,
    boardsData: data ?? [],
    muatateBoardsList: mutate,
    success,
  };
};
