import { useState } from "react";

const useSorting = (initialData) => {
  const [sortedData, setSortedData] = useState(initialData);
  const [sorting, setSorting] = useState({});

  const sortData = (columnId, sortState, data) => {
    const sorted = [...data].sort((a, b) => {
      if (columnId === "location") {
        // Sort by board first
        const boardComparison = a.board.localeCompare(b.board);
        if (boardComparison !== 0) {
          return sortState === "asc" ? boardComparison : -boardComparison;
        } else {
          // If boards are the same, sort by group
          return sortState === "asc"
            ? a.group.localeCompare(b.group)
            : -a.group.localeCompare(b.group);
        }
      } else if (columnId === "creation_log") {
        // Sort by creation date
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return sortState === "asc" ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });

    setSorting({
      [columnId]: sortState,
    });
    setSortedData(sorted);
  };

  return { sortedData, sorting, sortData, setSortedData };
};

export default useSorting;
