import { useSelector } from "react-redux";

const useBoardAndItems = () => {
  const { currentBoard, itemIds } = useSelector((state) => state.monday);

  return {
    currentBoard,
    itemIds,
  };
};

export default useBoardAndItems;
