import React from "react";
import { useState, useEffect } from "react";
import { Avatar, Card, Typography, Button, Space } from "antd";
import mondaySdk from "monday-sdk-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;
const { Text } = Typography;
const client_api_key = process.env.REACT_APP_CLIENT_ID;
const monday_app_version_id = process.env.REACT_APP_SIMB_MONDAY_APP_VERSION_ID;
const uri = process.env.REACT_APP_URI;

const monday = mondaySdk();

const AuthorizePage = () => {
  const [userid, setUserid] = useState(false);
  const [fetchAuth, setFetchAuth] = useState(false);
  const [appFeature, setAppFeature] = useState();
  const [authUrl, setAuthUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    monday.get("context").then((res) => {
      setUserid(res.data.user.id);
      setAppFeature(res.data.appFeature.type);
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams({ client_id: client_api_key });
    if (monday_app_version_id)
      searchParams.set("app_version_id", monday_app_version_id);

    setAuthUrl(
      `https://auth.monday.com/oauth2/authorize?${searchParams.toString()}`
    );
  }, []);

  useEffect(() => {
    if (fetchAuth) {
      function actionToExecute() {
        //replace the url
        axios
          .post(`${uri}/simb/check-userid-onboarding`, { userid: userid })
          .then((res) => {
            if (!res.data.error) {
              if (res.data.data) {
                clearInterval(intervalId);

                if (appFeature !== "AppFeatureItemMenuAction") {
                  navigate("/");
                } else {
                  navigate("/board-subItem-menu");
                }
              }
            }
          })
          .catch((error) => {
            console.log("error => ", error);
          });
      }
      const intervalId = setInterval(actionToExecute, 1000);
      return () => clearInterval(intervalId);
    }
  }, [userid, fetchAuth, navigate, appFeature]);

  const authorize = () => {
    setFetchAuth(true);
    monday.execute("openLinkInTab", {
      url: authUrl,
    });
  };

  const refetchAuth = () => {
    setFetchAuth(true);
  };

  return (
    <div className="flex h-screen">
      <div className="m-auto ">
        <div className="flex items-center justify-center">
          <Space direction="vertical" className="p-3 ">
            <Text type="secondary  my-0">Please authorize monday.com</Text>
            {/* <Progress className="my-0" percent={100} size={[300]} /> */}
          </Space>
        </div>
        <Card
          style={{
            width: 300,
          }}
          cover={
            <img height="225px" alt="SIMB logo" src="/img/simb-logo.png" />
          }
        >
          <div className="flex flex-col gap-2 mb-5">
            <Button
              onClick={() => authorize()}
              className="flex items-center justify-center w-full px-4 py-2 text-[1rem] h-[40px]"
              type="primary"
              icon={<img width={24} src="/img/monday.png" alt="monday icon" />}
            >
              Authorize Monday
            </Button>
            <span>
              If page does not open please{" "}
              <a
                onClick={refetchAuth}
                rel="noreferrer"
                href={authUrl}
                target="_blank"
                className="cursor-pointer"
              >
                click here
              </a>
            </span>
          </div>
          <Meta
            avatar={<Avatar src="/img/man.gif" />}
            title="Thank You"
            description="Click on “Authorize Monday” to start adding items to multiple boards"
          />
        </Card>
      </div>
    </div>
  );
};

export default AuthorizePage;
