import useUser from "../../context/userHook";
import { syncDeletion } from "../../../utils/requests/settingsRequest";
import { useState } from "react";
import useTokenHook from "../../context/useTokenHook";

const useSyncDeletion = () => {
  const { accountId } = useUser();
  const { token } = useTokenHook();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateSyncDeletion = async (setting) => {
    setLoading(true);
    setError(null);
    try {
      const response = await syncDeletion(token, accountId, setting);
      setData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    syncDeletionSetting: data,
    error,
    loading,
    updateSyncDeletion,
  };
};

export default useSyncDeletion;
