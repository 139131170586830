import React, { useState, useEffect, useRef } from "react";
import { formatTime, tooltipDate } from "../../utils/formatDate";
import getColumnIcon from "../../utils/columnIcons";
import {
  generateWorkspaceUrl,
  generateBoardUrl,
  generateItemUrl,
} from "../../utils/mondayLinks";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  BreadcrumbsBar,
  BreadcrumbItem,
} from "monday-ui-react-core";
import ButtonComponent from "../Button";
import {
  DropdownChevronRight,
  Alert,
  Unlocked,
  Delete,
  Workspace,
  Board,
  Group,
  Check,
} from "monday-ui-react-core/icons";
import mondaySdk from "monday-sdk-js";
import { useSlug } from "../../hooks/context/useSlug";
import useSorting from "../../hooks/itemLocations/useSort";
import useDeleteItems from "../../hooks/api/useDeleteItems";
import useUser from "../../hooks/context/userHook";
import useItemLocations from "../../hooks/api/useItemLocations";
import Spinner from "../Spinner";
import CustomPagination from "../Pagination"; // Import the pagination component
import useBreadcrumbBarResize from "../../hooks/componentHooks/resizeBreadcrumb";
import { itemLocationColumns } from "./columns";

const monday = mondaySdk();

const ItemsOnBoardTable = ({ tableData }) => {
  const { userId } = useUser();
  const [itemLocations, setItemLocations] = useState(tableData);
  const { sortedData, sorting, sortData, setSortedData } =
    useSorting(tableData); //hook for sorting table data from desc to asc
  const { newData } = useDeleteItems(tableData); // delete hooks
  const { slug } = useSlug(); // gets account slug for breadcrumb links
  const [expandedRows, setExpandedRows] = useState([]);
  const { mutateItemLocations, isValidating } = useItemLocations();
  // State to track the currently toggled row
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const locationColumnRef = useRef(null);

  useBreadcrumbBarResize(locationColumnRef.current);

  useEffect(() => {
    setItemLocations(newData);
    setSortedData(newData);
  }, [newData, setSortedData]);

  const handleSortChange = (columnId, sortState) => {
    sortData(columnId, sortState, itemLocations);
    setItemLocations(sortedData);
  };

  const handleToggle = (boardId, itemId) => {
    try {
      setExpandedRows((current) => {
        if (current.includes(`${boardId}-${itemId}`)) {
          return current.filter((id) => id !== `${boardId}-${itemId}`); // Remove boardId if it's already expanded
        } else {
          return [...current, `${boardId}-${itemId}`]; // Add boardId if it's not already expanded
        }
      });
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  };

  const toggleAll = () => {
    try {
      if (expandedRows.length > 0) {
        setExpandedRows([]); // Collapse all if all rows are expanded
      } else {
        setExpandedRows(
          itemLocations.map(
            (item) => `${item.targetBoardId}-${item.targetItemId}`
          )
        ); // Expand all
      }
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  };

  const handleRemoveItem = async (
    userId,
    targetItemId,
    targetBoardId,
    targetBoardName
  ) => {
    try {
      monday
        .execute("openAppFeatureModal", {
          urlPath: "/remove-modal",
          urlParams: {
            userId: userId,
            targetItemId: targetItemId,
            targetBoardId: targetBoardId,
            boardName: targetBoardName,
          },
          height: "240px",
          width: "500px",
        })
        .then((res) => {
          if (res.data.closed) {
            mutateItemLocations();
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnlinkItem = async (
    userId,
    targetItemId,
    targetBoardId,
    targetBoardName
  ) => {
    try {
      monday
        .execute("openAppFeatureModal", {
          urlPath: "/unlink-modal",
          urlParams: {
            userId: userId,
            targetItemId: targetItemId,
            targetBoardId: targetBoardId,
            boardName: targetBoardName,
          },
          height: "240px",
          width: "500px",
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.closed) {
            mutateItemLocations();
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAll = async () => {
    const itemIds = itemLocations.map((item) => item.targetItemId);
    const serializedItemIds = itemIds.join(",");

    monday
      .execute("openAppFeatureModal", {
        urlPath: "/delete-modal",
        urlParams: { userId: userId, itemIds: serializedItemIds },
        height: "275px",
        width: "500px",
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = itemLocations.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(itemLocations.length / itemsPerPage);

  return (
    <div className="w-full">
      <div className="flex justify-end mb-2">
        <ButtonComponent
          kind="primary"
          size="small"
          text={expandedRows.length > 0 ? "Collapse All" : "Expand All"}
          onClick={toggleAll}
        />
      </div>
      <div className="w-full">
        <Table
          size="large"
          className="max-h-[596px]"
          columns={itemLocationColumns}
        >
          <TableHeader>
            {itemLocationColumns.map((headerCell, index) => (
              <TableHeaderCell
                key={index}
                title={headerCell.title}
                onSortClicked={
                  headerCell.sort
                    ? (sortState) => handleSortChange(headerCell.id, sortState)
                    : undefined
                }
                sortState={sorting[headerCell.id]}
                infoContent={headerCell.infoContent}
              />
            ))}
          </TableHeader>
          <TableBody className="w-full">
            {currentItems.map((board, index) => {
              // Determine if this row's shared columns should be visible const
              const isVisible = expandedRows.includes(
                `${board.targetBoardId}-${board.targetItemId}`
              );
              return (
                <div
                  onClick={
                    board.tags.length > 0
                      ? () => {
                          handleToggle(board.targetBoardId, board.targetItemId);
                        }
                      : null
                  }
                  key={`${board.targetBoardId} + ${index}`}
                >
                  <TableRow
                    className={`h-auto ${
                      board.tags.length === 0 ? "" : "cursor-pointer"
                    }`}
                  >
                    <TableCell className="relative" ref={locationColumnRef}>
                      {board.tags.length > 0 ? (
                        <DropdownChevronRight
                          className={`absolute mr-2 transition ${
                            isVisible ? "rotate-90" : ""
                          }`}
                        />
                      ) : (
                        <span className="absolute flex items-center justify-center mr-2">
                          <Tooltip
                            className="max-w-[375px]"
                            position={Tooltip.positions.RIGHT}
                            content="This board does not
have any shared columns. To share columns, ensure they have the same column
name."
                            modifiers={[
                              {
                                name: "preventOverflow",
                                options: {
                                  mainAxis: false,
                                },
                              },
                              {
                                name: "flip",
                                options: {
                                  fallbackPlacements: [],
                                },
                              },
                            ]}
                            withMaxWidth
                          >
                            <Alert className="text-[#EAAA15]" />
                          </Tooltip>
                        </span>
                      )}
                      <div>
                        <BreadcrumbsBar
                          type="navigation"
                          className={`p-0 m-0 breadcrumb-locations`}
                        >
                          <Tooltip
                            className="flex items-center breadcrumb-tooltip"
                            content={`${
                              !board.board
                                ? "This item was added in a private board"
                                : !board.workspaceName
                                ? "Main Workspace"
                                : `${board.workspaceName}`
                            }`}
                          >
                            <BreadcrumbItem
                              className="item-breadcrumb"
                              onClick={(e) => {
                                e.stopPropagation();
                                const workspaceId = !board.workspaceId
                                  ? -1
                                  : board.workspaceId;
                                const url = generateWorkspaceUrl(
                                  slug,
                                  workspaceId
                                );
                                window.open(
                                  url,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                              isClickable="true"
                              icon={Workspace}
                            />
                          </Tooltip>
                          <Tooltip className="breadcrumb-tooltip">
                            <BreadcrumbItem
                              className="item-breadcrumb shorten-breadcrumb"
                              onClick={(e) => {
                                e.stopPropagation();
                                const url = generateBoardUrl(
                                  slug,
                                  board.targetBoardId
                                );
                                window.open(
                                  url,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                              isClickable="true"
                              icon={Board}
                              text={
                                !board.board ? "Private Board" : board.board
                              }
                            />
                          </Tooltip>
                          <Tooltip className="breadcrumb-tooltip">
                            <BreadcrumbItem
                              className="item-breadcrumb shorten-breadcrumb"
                              onClick={(e) => {
                                e.stopPropagation();
                                const url = generateItemUrl(
                                  slug,
                                  board.targetBoardId,
                                  board.targetItemId
                                );
                                window.open(
                                  url,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                              isClickable="true"
                              icon={Group}
                              text={
                                !board.board ? "Private Group" : board.group
                              }
                            />
                          </Tooltip>
                        </BreadcrumbsBar>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Tooltip content={tooltipDate(board.created_at)}>
                        <p>{formatTime(board.created_at)}</p>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-wrap gap-2 ">
                        <Tooltip
                          className="max-w-[320px]"
                          content="Unlink this item from the root item and remove it from shared locations table. This action cannot be undone."
                        >
                          <ButtonComponent
                            text="Unlink"
                            color="negative"
                            kind="secondary"
                            size="small"
                            leftIcon={Unlocked}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUnlinkItem(
                                userId,
                                board.targetItemId,
                                board.targetBoardId,
                                board.board
                              );
                            }}
                            className="min-w-[6.25rem]"
                          />
                        </Tooltip>
                        <Tooltip
                          className="max-w-[320px]"
                          content="Remove this item from this shared location. To undo, add this
item to the required board from scratch."
                        >
                          <ButtonComponent
                            text="Remove"
                            kind="primary"
                            danger
                            leftIcon={Delete}
                            size="small"
                            color="negative"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveItem(
                                userId,
                                board.targetItemId,
                                board.targetBoardId,
                                board.board
                              );
                            }}
                            className="min-w-[6.25rem]"
                          />
                        </Tooltip>
                      </div>
                    </TableCell>
                    {isVisible && board.tags.length > 0 && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="relative grid grid-cols-2 row-span-2 px-4 py-2 cursor-default col-span-full"
                      >
                        <Table
                          className=""
                          columns={[
                            {
                              id: "shared_columns",
                              loadingStateType: "medium-text",
                              title: "Shared Columns",
                            },
                            {
                              id: "value",
                              loadingStateType: "medium-text",
                              title: "Value",
                            },
                          ]}
                        >
                          <TableHeader>
                            <TableHeaderCell title="Shared Columns" />
                            <TableHeaderCell title="Value" />
                          </TableHeader>
                          <TableBody>
                            {board.tags.map((tag, index) => {
                              const Icon = getColumnIcon(tag.type);

                              return (
                                <>
                                  <TableRow key={index}>
                                    <TableCell>
                                      {Icon && (
                                        <Icon className="mr-1.5 text-[0.875rem] column-icon" />
                                      )}
                                      <span>{tag.title}</span>
                                    </TableCell>
                                    <TableCell>
                                      {tag.type === "checkbox" ? (
                                        tag.value === true ? (
                                          <Check />
                                        ) : (
                                          " "
                                        )
                                      ) : (
                                        tag.value
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        </Table>

                        {isValidating && (
                          <div className="relative">
                            <span className="absolute top-[50%] left-[8px]">
                              <Spinner size={16} />
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </TableRow>
                </div>
              );
            })}
          </TableBody>
        </Table>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center mt-4">
          <CustomPagination
            pageCount={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}

      <div className="flex justify-end w-full mt-2">
        <Tooltip content="Delete this item from all shared locations including the original board. This action cannot be undone">
          <ButtonComponent
            text="Delete All"
            kind="primary"
            danger
            leftIcon={Delete}
            size="small"
            color="negative"
            onClick={handleDeleteAll}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ItemsOnBoardTable;
