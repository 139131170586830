import { useState } from "react";
import useSWR from "swr";
import useUser from "../context/userHook";
import useBoardAndItems from "../context/useBoardAndItems";
import { useNavigate } from "react-router-dom";
import useTokenHook from "../context/useTokenHook";
import { getWorkspacesAndBoards } from "../../utils/requests/workspacesAndBoardsRequest";

export const useWorkspaceAndBoards = () => {
  const [success, setSuccess] = useState(false);
  const { userId } = useUser();
  const { currentBoard, itemIds } = useBoardAndItems();
  const { token } = useTokenHook();

  const navigate = useNavigate();

  const { isLoading, error, data, isValidating, mutate } = useSWR(
    userId ? `workspacesAndBoards:${userId}` : null,
    () => getWorkspacesAndBoards(token, itemIds, currentBoard),
    {
      revalidateOnMount: true,
      onSuccess: () => {
        setSuccess(true);
      },
      onError: (error) => {
        setSuccess(false);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message === "User not found"
        ) {
          navigate("/onboarding");
        }
      },
      onErrorRetry: (error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message === "User not found"
        ) {
          navigate("/onboarding");
        }
      },
    }
  );

  return {
    isLoading,
    error,
    isValidating,
    workspacesAndBoards: data ?? [],
    mutateWorkspacesAndBoards: mutate,
    success,
  };
};
