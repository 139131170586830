export const BOARDS_ENDPOINT = "/simb/all-boards-filtered";

export const WORKSPACES_AND_BOARDS = "/simb/workspaces-and-boards";

export const UPDATE_SHARED_SUBITEMS_SETTINGS =
  "/simb/update/shared-subitem-settings";

export const GET_SHARED_SUBITEMS_SETTINGS = "/simb/get/shared-subitem-settings";

export const ADD_ITEM_ENDPOINT = "/simb/add-item-and-sync";

export const ITEM_LOCATIONS_ENDPOINT = "/simb/item-locations";

export const DELETE_ALL = "/simb/delete-all";

export const DELETE = "/simb/delete-item";

export const UNLINK = "/simb/unlink-item";

export const GET_CHANGELOG = "/simb/changelog";

export const GET_SETTINGS = "/simb/settings/account-settings";

export const SYNC_DELETION_SETTINGS = "/simb/settings/sync-deletion";

export const VISUAL_INDICATOR_SETTINGS = "/simb/settings/visual-indicator";
