import { useEffect, useState } from "react";
import ButtonComponent from "../Button";
import { Add } from "monday-ui-react-core/icons";
import AddItemDropdown from "./AddItemDropdown";
import { MondayApiQueryInstance } from "../../services/monday/monday-service-ts";

interface AddItemButtonProps {
  centered: boolean;
}

const AddItemButton = ({ centered }: AddItemButtonProps) => {
  const [open, setOpen] = useState(false);
  const [bigAccount, setBigAccount] = useState(true);

  useEffect(() => {
    const fetchAccountSize = async () => {
      try {
        const checkAccountSize = localStorage.getItem("isBigAccount");

        if (checkAccountSize !== null) {
          setBigAccount(JSON.parse(checkAccountSize));
        } else {
          const boardSize = await MondayApiQueryInstance.getAccountSize();

          const isBigAccount = boardSize.length >= 500;

          localStorage.setItem("isBigAccount", JSON.stringify(isBigAccount));
          setBigAccount(isBigAccount);
        }
      } catch (error) {
        throw error;
      }
    };

    fetchAccountSize();
  }, []);

  return (
    <div className="relative">
      <ButtonComponent
        className="add-item-button"
        relative
        leftIcon={Add}
        text="Add to Another Board"
        onClick={() => {
          setOpen(!open);
        }}
      />
      {open && (
        <AddItemDropdown
          centered={centered}
          setOpen={setOpen}
          fullWidth={false}
          bigAccount={bigAccount}
        />
      )}
    </div>
  );
};

export default AddItemButton;
