import { useEffect } from "react";

const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (event.target instanceof Element) {
        const isRadioWrapper =
          event.target.classList.contains("MuiSvgIcon-root");
        const retryButton = event.target.classList.contains("retry-button");
        let isAddItemButton = false;
        let currentElement: Element | null = event.target;

        // Traverse up the DOM tree to check if any ancestor has the 'add-item-button' class
        while (currentElement) {
          if (currentElement.classList.contains("add-item-button")) {
            isAddItemButton = true;
            break;
          }
          currentElement = currentElement.parentElement;
        }

        if (isRadioWrapper || retryButton || isAddItemButton) {
          return;
        }

        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, callback]);
};

export default useClickOutside;
