import React, { useState, useEffect } from "react";
import mondaySdk from "monday-sdk-js";

import useSubitemSettings from "../../hooks/api/useSubitemSettings";
import { Switch, Tooltip } from "antd";
import { Info } from "monday-ui-react-core/icons";

const monday = mondaySdk();

const SharedSubitemSettings = () => {
  const [sharedSettings, setSharedSettings] = useState(true);
  const { isLoading, error, subitemSettings, updateSettings, userId, itemIds } =
    useSubitemSettings();

  useEffect(() => {
    setSharedSettings(subitemSettings);
  }, [subitemSettings]);

  const handleClick = async (checked) => {
    try {
      setSharedSettings(checked);
      await updateSettings(userId, itemIds, checked);

      monday.execute("notice", {
        message: checked
          ? "New subitems on this subItem will now appear on all other locations"
          : "New subitems WILL NOT appear on other subItem locations",
        type: "info", // or "error" (red), or "info" (blue)
        timeout: 5000,
      });
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  if (error) {
    console.error(error);
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <Switch
        checkedChildren="On"
        unCheckedChildren="Off"
        onChange={(checked) => {
          handleClick(checked);
        }}
        checked={sharedSettings}
        loading={isLoading}
      />
      <div className="flex flex-row items-center gap-1">
        <p className="mr-0.5 ms-2">Shared subitems</p>
        <Tooltip title="When enabled, newly created subitems on this item will sync with the sync item.">
          <Info />
        </Tooltip>
      </div>
    </div>
  );
};

export default SharedSubitemSettings;
