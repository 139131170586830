import React, { useEffect, useState } from "react";
import { generateBoardUrl, generateItemUrl } from "../../utils/mondayLinks";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  Label,
} from "monday-ui-react-core";
import { DropdownChevronRight } from "monday-ui-react-core/icons";
import { formatDate } from "../../utils/formatDate";
import { getBoardUsageDetails } from "../../services/monday-service";

const UsageTableRow = ({
  index,
  boardId,
  items,
  slug,
  isVisible,
  handleToggle,
}) => {
  const [boardName, setBoardName] = useState();
  const [itemsData, setItemsData] = useState([]);
  const [boardState, setBoardState] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getBoardUsageDetails(boardId, items);

        setBoardName(response?.boardData[0]?.name);
        setBoardState(response?.boardData[0]?.state);
        setItemsData(response?.itemData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [boardId, items]);

  return (
    <div
      onClick={() => {
        if (boardName) {
          handleToggle(boardId);
        }
      }}
      key={boardId + index}
    >
      <TableRow className="h-auto cursor-pointer">
        <TableCell className="relative ">
          {loading ? (
            <Skeleton height={20} type="text" size="p" width={250} />
          ) : (
            <>
              {boardName && (
                <DropdownChevronRight
                  className={`absolute mr-2 transition ${
                    isVisible ? "rotate-90" : ""
                  }`}
                />
              )}
              <div className="flex items-center pl-6">
                <a
                  href={generateBoardUrl(slug, boardId)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {boardName ? boardName : "Private Board"}
                </a>
                {(boardState === "deleted" || boardState === "archived") && (
                  <Label
                    className="ml-3"
                    text={boardState.toUpperCase()}
                    color={boardState === "archived" ? "dark" : "negative"}
                    labelClassName="label-inner-text-wrapper"
                  />
                )}
              </div>
            </>
          )}
        </TableCell>
        <TableCell>{items.length}</TableCell>
        {isVisible && items.length > 0 && (
          <div className="flex row-span-2 px-4 py-2 col-span-full">
            <Table
              className="w-auto"
              columns={[
                {
                  id: "items",
                  loadingStateType: "medium-text",
                  title: "Items",
                },
                {
                  id: "creation_log",
                  loadingStateType: "medium-text",
                  title: "Creation Log",
                },
              ]}
            >
              <TableHeader className="!z-0">
                <TableHeaderCell title="Items" />
                <TableHeaderCell title="Creation Log" />
              </TableHeader>
              <TableBody>
                {items.map((item) => {
                  const itemData = itemsData.find(
                    (data) => data.id.toString() === item.itemId.toString()
                  );

                  return (
                    <>
                      {itemData && (
                        <TableRow key={item.itemId}>
                          <TableCell>
                            <a
                              href={generateItemUrl(slug, boardId, item.itemId)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {itemData ? itemData.name : item.itemId}
                            </a>
                          </TableCell>
                          <TableCell>{formatDate(item.created_at)}</TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </TableRow>
    </div>
  );
};

export default UsageTableRow;
