import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import mondaySdk from "monday-sdk-js";
import useTokenHook from "../hooks/context/useTokenHook";

const monday = mondaySdk();

const uri = process.env.REACT_APP_URI;

// Create a context
const SettingsContext = createContext();

// Create a provider component
export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { token } = useTokenHook();

  const fetchSettings = async (targetItemId, targetBoardId) => {
    try {
      setIsLoading(true);
      if (userId !== null) {
        const response = await axios.get(
          `${uri}/simb/user-settings/${targetBoardId}/${targetItemId}`,
          {
            headers: {
              "userid": userId,
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );

        setSettings(response.data.userSettings);
        setError(null);
      }
    } catch (err) {
      setError(err.message);
      setSettings(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    monday
      .get("context", { apiVersion: "2023-10" })
      .then((res) => {
        setUserId(res.data.user.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  const value = { settings, isLoading, fetchSettings, error };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

// Custom hook to use the settings context
export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
