import React from "react";
import { Pagination } from "@mui/material";

const CustomPagination = ({ pageCount, currentPage, onPageChange }) => {
  return (
    <Pagination
      count={pageCount}
      page={currentPage}
      onChange={onPageChange}
      color="primary"
      shape="rounded"
    />
  );
};

export default CustomPagination;
