import { useEffect, useState } from "react";
import { Settings } from "monday-ui-react-core/icons";
import Drawer from "@mui/material/Drawer";
import SettingsDrawerList from "./SettingsDrawerList";
import useGetSettings from "../../../hooks/api/Settings/useGetSettings";
import ButtonComponent from "../../Button";
import { Tooltip } from "monday-ui-react-core";
import { SettingsData } from "./types/settings-drawer-interface";

const SettingsDrawer = () => {
  const [open, setOpen] = useState(false);
  const { settingsData } = useGetSettings();

  const [switchValues, setSwitchValues] = useState<SettingsData>(settingsData);

  useEffect(() => {
    if (settingsData) {
      setSwitchValues(settingsData);
    }
  }, [settingsData]);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <div>
      <Tooltip content="Settings">
        <ButtonComponent
          text=""
          onClick={() => toggleDrawer(true)}
          rightIcon={Settings}
          size="small"
          kind="tertiary"
        />
      </Tooltip>
      <Drawer open={open} anchor="right" onClose={() => toggleDrawer(false)}>
        <SettingsDrawerList
          switchValues={switchValues}
          setSwitchValues={setSwitchValues}
          handleCloseDrawer={toggleDrawer}
        />
      </Drawer>
    </div>
  );
};

export default SettingsDrawer;
