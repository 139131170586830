import { Avatar, Card, Row, Button, Typography, Progress, Space } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/PageLoader";

import mondaySdk from "monday-sdk-js";
import axios from "axios";
import { useState, useEffect } from "react";

const uri = process.env.REACT_APP_URI;
const { Meta } = Card;
const { Title, Paragraph, Text } = Typography;

const Screen1 = () => {
  const [userid, setUserid] = useState(null);
  const [spin, setSpin] = useState(false);
  const navigate = useNavigate();
  const monday = mondaySdk();

  useEffect(() => {
    monday.get("context").then((res) => {
      setUserid(res.data.user.id);
    });
  });

  const decision = () => {
    setSpin(true);

    axios
      .post(`${uri}/simb/check-userid`, { userid: userid })
      .then((res) => {
        if (!res.data.error) {
          if (res.data.data) {
            axios
              .post(
                `${uri}/simb/change-onboarding-status`,
                {
                  userId: userid,
                  status: true,
                },
                {
                  headers: {
                    userid: userid,
                  },
                }
              )
              .then(() => {
                setSpin(false);
                navigate("/");
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setSpin(false);
            navigate("/onboarding/screen3");
          }
        }
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };

  return (
    <>
      {!spin ? (
        <div className="flex h-screen">
          <div className="p-2 m-auto">
            <div className="flex items-center justify-center">
              <Space direction="vertical" className="p-3 ">
                <Text type="secondary  my-0">Onboarding progress</Text>
                <Progress className="my-0" percent={50} size={[300]} />
              </Space>
            </div>
            <Card>
              <Row className="flex flex-col items-center justify-center p-0 ">
                {/* <img
                width={400}
                className=""
                src="/img/board-subItem.gif"
                alt="monday board"
              /> */}
                <Title className="w-full pb-0 mt-0" level={5}>
                  Same Item Multiple Boards Features
                </Title>
                <Paragraph className="w-full ">
                  Discover the powerful capabilities of SIMB:
                </Paragraph>
              </Row>

              <Row justify="space-evenly   w-full ">
                <div className="w-full ">
                  <Card className="w-full shadow" style={{ marginTop: 16 }}>
                    <Meta
                      avatar={<Avatar src="/img/rows.gif" />}
                      title="Shared Items Across Boards"
                      description="Same Item in multiple boards” allows you to share your item in multiple other boards. Changes in the item’s name will result in changes across all other boards."
                    />
                  </Card>
                </div>

                <div className="w-full">
                  <Card className="w-full shadow" style={{ marginTop: 16 }}>
                    <Meta
                      avatar={<Avatar src="/img/columns.gif" />}
                      title="Shared Columns"
                      description="Changes in columns on a shared item will show 
                    on all other items. For example, you can change status to “Done”
                     and it will show on your other boards as well. 
                     "
                    />
                    <p className="pl-6 mt-8 ml-6 ms-5">
                      IMPORTANT: Columns are only shared across boards when they
                      have the same column name.
                    </p>
                  </Card>
                </div>

                <div className="w-full ">
                  <Card className="w-full shadow" style={{ marginTop: 16 }}>
                    <Meta
                      avatar={<Avatar src="/img/updates.gif" />}
                      title="Shared Updates"
                      description="Any new update posted on the item from any board, will show on all other boards as well"
                    />
                  </Card>
                </div>
              </Row>

              <Row className="flex justify-end">
                {/* <a href="/onboarding/screen3"> */}

                {/* Add spinner while loading here */}
                <Button
                  onClick={() => decision()}
                  className="mt-5 animate-bounce"
                  type="primary"
                  shape="round"
                  icon={<ArrowRightOutlined />}
                >
                  Next
                </Button>
              </Row>
            </Card>
          </div>
        </div>
      ) : (
        <div>
          <div className="App">
            <PageLoader />
          </div>
        </div>
      )}
    </>
  );
};

export default Screen1;
