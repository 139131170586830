import simbApi from ".";
import { BOARDS_ENDPOINT } from "../../data/constants/endpoints";

export const getBoardsList = async (
  access_token,
  itemIdsArray,
  currentBoard,
  workspaceId
) => {
  try {
    const response = await simbApi.post(
      BOARDS_ENDPOINT,
      {
        itemid: itemIdsArray,
        boardid: currentBoard,
        workspaceId: workspaceId,
      },
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    return response?.data?.data;
  } catch (error) {
    console.error("axios request:", error);
    throw error;
  }
};
