import { useEffect } from "react";
import "./App.css";
import "monday-ui-react-core/dist/main.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/home";
import Onboarding from "./pages/onbording";
import Screen1 from "./pages/onboarding/screen1";
// import Screen2 from "./pages/onboarding/screen2.js.bak";
import Screen3 from "./pages/onboarding/screen3";
import { ConfigProvider } from "antd";
import "./index.css";
import BatchItemMenu from "./pages/batchItemMenu";
import BoardItemMenu from "./pages/BoardItemMenu";
import { SettingsProvider } from "./context/SettingsContext";
import DetailedUsageReport from "./pages/DetailedUsageReport";
import { useDispatch } from "react-redux";
import { fetchMondayContext } from "./redux/slices/mondaySlice";
import useUser from "./hooks/context/userHook";
import PageLoader from "./components/PageLoader";
import DeleteModal from "./components/DeleteModalUI/DeleteModal";
import RemoveModal from "./components/DeleteModalUI/RemoveModal";
import UnlinkModal from "./components/DeleteModalUI/UnlinkModal";
import { v4 as uuidv4 } from "uuid";
import { fetchAccessToken } from "./redux/slices/accessTokenSlice";
import useTokenHook from "./hooks/context/useTokenHook";

export default function App() {
  const dispatch = useDispatch();
  const { userId } = useUser();
  const { token } = useTokenHook();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeToken = async (userId) => {
      try {
        const response = await dispatch(fetchAccessToken(userId));

        if (response.payload === "Request failed with status code 400") {
          navigate("/onboarding");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!token && userId) {
      initializeToken(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token, userId]);

  useEffect(() => {
    dispatch(fetchMondayContext());
  }, [dispatch]);

  useEffect(() => {
    // Reset $crisp array
    window.$crisp = [];

    // Set CRISP_WEBSITE_ID if not on delete-modal path
    if (
      !(
        window.location.pathname.includes("delete-modal") ||
        window.location.pathname.includes("unlink-modal") ||
        window.location.pathname.includes("remove-modal")
      )
    ) {
      window.CRISP_WEBSITE_ID = process.env.REACT_APP_WEB_ID;

      // WE WOULD USE THE UUID AND STORE IT IN OUR DATABASE

      let crispUserToken = localStorage.getItem("crisp_user_token");

      if (crispUserToken) {
        window.CRISP_TOKEN_ID = crispUserToken;
      } else {
        crispUserToken = uuidv4();
        localStorage.setItem("crisp_user_token", crispUserToken);
        window.CRISP_TOKEN_ID = crispUserToken;
      }

      // Create and append Crisp chat script
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://client.crisp.chat/l.js";
      document.head.appendChild(script);

      // Cleanup function to remove the script when the component is unmounted
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);

  if (!userId) {
    return <PageLoader />;
  }

  return (
    <>
      <ConfigProvider theme={{ token: { fontFamily: "Figtree, sans-serif" } }}>
        <SettingsProvider>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              {/* <Route index element={<UpdateVersion />} /> */}

              <Route path="onboarding">
                <Route index element={<Onboarding />} />
                <Route path="screen1" element={<Screen1 />} />
                {/* <Route path="screen2" element={<Screen2 />} /> */}
                <Route path="screen3" element={<Screen3 />} />
              </Route>

              <Route index path="board-item-menu" element={<BoardItemMenu />} />
              <Route
                index
                path="batch-items-menu"
                element={<BatchItemMenu />}
              />

              <Route
                index
                path="detailed-usage"
                element={<DetailedUsageReport />}
              />
            </Route>
            <Route index path="delete-modal" element={<DeleteModal />} />
            <Route index path="remove-modal" element={<RemoveModal />} />
            <Route index path="unlink-modal" element={<UnlinkModal />} />
          </Routes>
        </SettingsProvider>
      </ConfigProvider>
    </>
  );
}
