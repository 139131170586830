import React from "react";
import useTokenHook from "../../hooks/context/useTokenHook";
import useUser from "../../hooks/context/userHook";
import useItemLocations from "../../hooks/api/useItemLocations";
import mondaySdk from "monday-sdk-js";
import useBoardAndItems from "../../hooks/context/useBoardAndItems";
import axios from "axios";
import { Group } from "monday-ui-react-core/icons";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { BoardGroups } from "./BoardsList";
import Spinner from "../Spinner";
import { useList } from "../../hooks/componentHooks/listHooks";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const uri = process.env.REACT_APP_URI;

interface GroupListProps {
  processUpdates: boolean;
  processSubitems: boolean;
  boardId: string;
  boardGroups: BoardGroups;
  boardSynced: boolean;
}

export type GroupType = {
  id: string;
  title: string;
  deleted: boolean;
  archived: boolean;
  color: string;
};

const GroupsList = ({
  processUpdates,
  processSubitems,
  boardId,
  boardGroups,
  boardSynced,
}: GroupListProps) => {
  const { userId, accountId } = useUser();
  const { currentBoard, itemIds } = useBoardAndItems();
  const { token } = useTokenHook();

  const { mutateItemLocations } = useItemLocations();

  const {
    isLoading: itemsLoader,
    removeItemLoading,
    setItemLoading,
    setItemDisabled,
    isDisabled: itemsDisabled,
    removeItemDisabled,
  } = useList();

  const addNewItem = async (toSyncGroupId: string, toSyncBoardId: string) => {
    try {
      //add the loading spinner
      setItemLoading(`${toSyncGroupId}-${toSyncBoardId}`);
      setItemDisabled(`${toSyncGroupId}-${toSyncBoardId}`);

      console.log("boolean:", processUpdates, processSubitems);

      // TODO: Must be removed and checked on the actual endpoint
      const statusResponse = await axios.post(
        `${uri}/check-paywall-status`,
        { itemid: itemIds, targetBoardId: toSyncBoardId },
        {
          headers: {
            userid: userId,
          },
        }
      );

      if (!statusResponse.data.data.status.status) {
        monday.execute("notice", {
          message: statusResponse.data.data.status.message,
          type: "error",
          timeout: 10000,
        });
        return;
      }
      //end of paywall block

      //add-subItem endpoint
      const addItem = await axios.post(
        `${uri}/simb/add-item-and-sync`,
        {
          payload: {
            accountId: accountId,
            itemIdArray: itemIds,
            originBoardId: currentBoard,
            target: {
              boardId: toSyncBoardId,
              groupId: toSyncGroupId,
            },
            processSubItem: processSubitems,
            processUpdates: processUpdates,
          },
        },
        {
          headers: {
            userid: userId,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!addItem.data.error) {
        monday.execute("notice", {
          message: `New item added`,
          type: "success", // or "error" (red), or "info" (blue)
          timeout: 10000,
        });

        mutateItemLocations();
      }
    } catch (error: any) {
      console.log("error => ", error.message);

      let errorMessage = `An error has occurred. Item did not sync successfully.`;

      if (error?.response?.data?.error) {
        errorMessage = error?.response?.data?.error;

        if (errorMessage === "User unauthorized to perform action") {
          errorMessage =
            "You do not have permissions to create or edit in this board.";
        }
      }

      removeItemDisabled(`${toSyncGroupId}-${toSyncBoardId}`);

      monday.execute("notice", {
        message: errorMessage,
        type: "error", // or "error" (red), or "info" (blue)
        timeout: 10000,
      });
    } finally {
      removeItemLoading(`${toSyncGroupId}-${toSyncBoardId}`);
    }
  };

  return (
    <List component="div" disablePadding>
      {boardGroups[boardId as string] ? (
        boardGroups[boardId as string].map((group: GroupType) => (
          <ListItemButton
            onClick={(e) => {
              e.stopPropagation();
              addNewItem(group.id, boardId);
            }}
            key={group.id}
            sx={{ pl: 4 }}
            disabled={
              boardSynced ||
              itemsDisabled.some((item: string) => item.endsWith(`-${boardId}`))
            }
          >
            <ListItemIcon
              sx={{
                minWidth: "unset",
                color: `${group.color}`,
              }}
              className="!w-auto mr-1"
            >
              <Group />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: "0.875rem",
                fontFamily: "Poppins",
                color: `${group.color}`,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              primary={group.title}
            />

            {itemsLoader.includes(`${group.id}-${boardId}`) && (
              <Spinner size={14} />
            )}
          </ListItemButton>
        ))
      ) : (
        <Spinner />
      )}
    </List>
  );
};

export default GroupsList;
