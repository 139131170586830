import { useState, useEffect } from "react";
import { getSlug } from "../../services/monday-service";

export const useSlug = () => {
  const [slug, setSlug] = useState();

  useEffect(() => {
    const fetchSlug = async () => {
      const slugURL = await getSlug();
      setSlug(slugURL);
    };

    fetchSlug();
  }, []);

  return {
    slug,
  };
};
