export const itemLocationColumns = [
  {
    id: "location",
    loadingStateType: "medium-text",
    title: "Location",
    sort: true,
    width: {
      min: 200,
      max: "100%",
    },
    infoContent: "Shared locations associated with this item",
  },
  {
    id: "creation_log",
    loadingStateType: "medium-text",
    title: "Creation Log",
    sort: true,
    infoContent: "The date-time of creation",
    width: {
      min: 120,
      max: 150,
    },
  },
  {
    id: "action",
    loadingStateType: "medium-text",
    title: "Action",
    sort: false,
    width: {
      min: 120,
      max: "100%",
    },
  },
];
