import React from "react";
import { Space, Progress } from "antd";
import Spinner from "../Spinner";

const UsageCharts = (props) => {
  const { boards, items, whitelisted, planName, loading } = props;

  return (
    <>
      <div className="flex items-center justify-center min-w-[300px]">
        <Space gap="small" wrap="wrap">
          {whitelisted ? (
            <div className="w-[200px] font-semibold text-center">
              You are whitelisted, you have unlimited items and boards.
            </div>
          ) : (
            <>
              <Space className="flex flex-col items-center justify-center">
                {loading ? (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="text-center">
                    <h4 className="m-0">{`${planName}`}</h4>
                    {planName !== "Enterprise Plan" && (
                      <Space>
                        <div>
                          <h5 className="m-0 mb-1">Shared Items</h5>
                          <Progress
                            className="item-usage"
                            type="dashboard"
                            percent={items.usagePercentItems}
                            format={() =>
                              `${items.itemsCount} out of ${items.maxItems}`
                            }
                            strokeColor={
                              items.usagePercentItems >= 100
                                ? "#D83A52"
                                : "#0073EA"
                            }
                            status={
                              items.usagePercentItems >= 100 ? "exception" : ""
                            }
                          />
                        </div>
                        <div>
                          <h5 className="m-0 mb-1">Shared Boards</h5>

                          <Progress
                            className="board-usage"
                            type="dashboard"
                            percent={boards.usagePercentBoards}
                            format={() =>
                              `${boards.boardsCount} out of ${boards.maxBoards}`
                            }
                            strokeColor={
                              boards.usagePercentBoards >= 100
                                ? "#D83A52"
                                : "#0073EA"
                            }
                            status={
                              boards.usagePercentBoards >= 100
                                ? "exception"
                                : ""
                            }
                          />
                        </div>
                      </Space>
                    )}
                  </div>
                )}
              </Space>
            </>
          )}
        </Space>
      </div>
    </>
  );
};

export default UsageCharts;
