import { configureStore } from "@reduxjs/toolkit";
import mondayReducer from "../slices/mondaySlice";
import listReducer from "../slices/listSlice"; // Import the list slice
import accessTokenReducer from "../slices/accessTokenSlice";

export const store = configureStore({
  reducer: {
    monday: mondayReducer,
    list: listReducer,
    accessToken: accessTokenReducer
  },
});
