import { useState, useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import {
  deleteAllItems,
  deleteItem,
  unlinkItem,
} from "../../utils/requests/itemRequests";
import useTokenHook from "../context/useTokenHook";

const monday = mondaySdk();

const useDeleteItems = (tableData) => {
  const [newData, setNewData] = useState(tableData);
  const { token } = useTokenHook();

  useEffect(() => {
    setNewData(tableData);
  }, [tableData]);

  const deleteAllHook = async (userId, itemIds) => {
    try {
      const response = await deleteAllItems(token, itemIds);

      if (!response.data.error) {
        monday.execute("notice", {
          message: "Items successfully deleted!",
          type: "success", // or "error" (red), or "info" (blue)
          timeout: 5000,
        });
      }
    } catch (error) {
      monday.execute("notice", {
        message: "Something went wrong! Items were not deleted.",
        type: "error", // or "error" (red), or "info" (blue)
        timeout: 5000,
      });
      console.error(error);
      throw error;
    }
  };

  const deleteHook = async (userId, itemId, boardId) => {
    try {
      const response = await deleteItem(token, itemId, boardId);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      monday.execute("notice", {
        message: "Item deleted!",
        type: "success", // or "error" (red), or "info" (blue)
        timeout: 5000,
      });
    } catch (error) {
      console.log(error);
      monday.execute("notice", {
        message: "Something went wrong! Item was not deleted.",
        type: "error", // or "error" (red), or "info" (blue)
        timeout: 5000,
      });
    }
  };

  const unlinkHook = async (userId, itemId, boardId) => {
    try {
      const response = await unlinkItem(token, itemId, boardId);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      monday.execute("notice", {
        message: "Item unlinked!",
        type: "success", // or "error" (red), or "info" (blue)
        timeout: 5000,
      });
    } catch (error) {
      console.error(error);
      monday.execute("notice", {
        message: "Something went wrong! Item is still linked.",
        type: "error", // or "error" (red), or "info" (blue)
        timeout: 5000,
      });
    }
  };

  return {
    deleteAllHook,
    deleteHook,
    unlinkHook,
    newData,
    setNewData,
  };
};

export default useDeleteItems;
