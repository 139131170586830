import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

export const fetchMondayContext = createAsyncThunk(
  "monday/fetchContext",
  (_, { rejectWithValue }) => {
    return monday
      .get("context")
      .then((response) => {
        const appFeatureType = response.data.appFeature.type;
        let itemIdsArray = [];

        appFeatureType === "AppFeatureItemBatchAction"
          ? (itemIdsArray = response.data.selectedPulsesIds)
          : itemIdsArray.push(response.data.itemId);

        return {
          userId: response.data.user.id,
          accountId: response.data.account.id,
          currentBoard: response.data.boardId,
          itemIds: itemIdsArray,
          appFeatureType: appFeatureType,
        };
      })
      .catch((error) => {
        // If an error occurs, reject the promise and handle it in the Redux slice
        return rejectWithValue(error.message);
      });
  }
);

const mondaySlice = createSlice({
  name: "monday",
  initialState: {
    userId: null,
    accountId: null,
    currentBoard: null,
    itemIds: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMondayContext.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMondayContext.fulfilled, (state, action) => {
        state.loading = false;
        state.userId = action.payload.userId;
        state.accountId = action.payload.accountId;
        state.currentBoard = action.payload.currentBoard;
        state.itemIds = action.payload.itemIds;
      })
      .addCase(fetchMondayContext.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default mondaySlice.reducer;
