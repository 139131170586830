import useSWR from "swr";
import useUser from "../context/userHook";
import useBoardAndItems from "../context/useBoardAndItems";
import { getItemLocations } from "../../utils/requests/itemRequests";
import { useNavigate } from "react-router-dom";
import useTokenHook from "../context/useTokenHook";
import { useState } from "react";

const useItemLocations = () => {
  const { userId } = useUser();
  const { itemIds, currentBoard } = useBoardAndItems();
  const { token } = useTokenHook();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { error, data, mutate, isValidating } = useSWR(
    token ? `itemLocations:${token}` : null,
    () => getItemLocations(token, itemIds, currentBoard, userId),
    {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: (error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message === "User not found"
        ) {
          setIsLoading(false);
          navigate("/onboarding");
        }
      },
      onErrorRetry: (error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message === "User not found"
        ) {
          setIsLoading(false);
          navigate("/onboarding");
        }
      },
    }
  );

  return {
    isLoading,
    error,
    tableData: data || [],
    itemIds: itemIds || [],
    mutateItemLocations: mutate,
    isValidating,
  };
};

export default useItemLocations;
