/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Chart } from "monday-ui-react-core/icons";
import { Dropdown, Space } from "antd";
import UsageCharts from "./UsageCharts";
import mondaySdk from "monday-sdk-js";
import axios from "axios";
import ButtonComponent from "../Button";
import { Tooltip } from "monday-ui-react-core";
import useTokenHook from "../../hooks/context/useTokenHook";

const monday = mondaySdk();

const uri = process.env.REACT_APP_URI;

const Usage = () => {
  const [userId, setUserId] = useState();
  const [accountId, setAccountId] = useState();
  const { token } = useTokenHook();
  const [boards, setBoards] = useState({
    boardsCount: 0,
    maxBoards: 0,
    usagePercentBoards: 0,
  });
  const [items, setItems] = useState({
    itemsCount: 0,
    maxItems: 0,
    usagePercentItems: 0,
  });
  const [whitelisted, setWhitelisted] = useState(false);
  const [planId, setPlanId] = useState("");
  const [planName, setPlanName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    monday
      .get("context")
      .then((res) => {
        setUserId(res.data.user.id);
        setAccountId(res.data.account.id);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const fetchUsage = async () => {
    try {
      const response = await axios.get(`${uri}/simb/usage/usageCharts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "userid": userId,
          // "accountId": accountId,
        },
      });

      if (response.data.whiteListedAccount) {
        setWhitelisted(response.data.whiteListedAccount);
      }

      if (response.data.usageData) {
        const {
          plan_id,
          planName,
          maxItems,
          maxBoards,
          itemUsage,
          boardUsage,
          usagePercentBoards,
          usagePercentItems,
        } = response.data.usageData;

        setPlanId(plan_id);
        setPlanName(planName);
        setItems({
          itemsCount: itemUsage,
          maxItems: maxItems,
          usagePercentItems: usagePercentItems,
        });
        setBoards({
          boardsCount: boardUsage,
          maxBoards: maxBoards,
          usagePercentBoards: usagePercentBoards,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId && accountId) {
      // Ensure fetchUsage is called when userId and accountId are set
      fetchUsage();
    }
  }, [userId, accountId]);

  const menu = {
    items: [
      {
        key: "1",
        label: (
          <UsageCharts
            boards={boards}
            items={items}
            whitelisted={whitelisted}
            planId={planId}
            planName={planName}
            loading={loading}
          />
        ),
      },
      {
        key: "2",
        label: (
          <Link to="/detailed-usage" className="detailed-usage-link">
            See Detailed Report
          </Link>
        ),
      },
    ],
  };

  return (
    <Space>
      <Dropdown
        menu={menu}
        onClick={(e) => {
          fetchUsage();
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <Tooltip content="Account Usage" position="top">
          <ButtonComponent rightIcon={Chart} size="small" kind="tertiary" />
        </Tooltip>
      </Dropdown>
    </Space>
  );
};

export default Usage;
