import AuthorizePage from "./onboarding/screen3";

const Onboarding = () => {
  return (
    <>
      <AuthorizePage />
    </>
  );
};

export default Onboarding;
