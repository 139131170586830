import { useMemo } from "react";

/**
 * Custom hook for searching through an array of objects.
 * @param items Array of objects to search through.
 * @param searchParam The search term.
 * @param searchKey The key to search by.
 * @returns Filtered array of objects that match the search parameter.
 */
function useSearchList<T>(
  items: T[],
  searchParam: string,
  searchKey: keyof T
): T[] {
  // Function to normalize the search term by removing special characters and converting to lower case
  const normalize = (text: string) =>
    text.replace(/[^\w\s]/gi, "").toLowerCase();

  const normalizedSearchParam = useMemo(
    () => normalize(searchParam),
    [searchParam]
  );

  const filteredItems = useMemo(() => {
    if (!normalizedSearchParam) return items;

    return items.filter((item) =>
      normalize(String(item[searchKey])).includes(normalizedSearchParam)
    );
  }, [items, normalizedSearchParam, searchKey]);

  return filteredItems;
}

export default useSearchList;
