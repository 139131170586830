import simbApi from ".";
import { WORKSPACES_AND_BOARDS } from "../../data/constants/endpoints";

export const getWorkspacesAndBoards = async (
  access_token,
  itemIdsArray,
  currentBoard
) => {
  try {
    const response = await simbApi.post(
      WORKSPACES_AND_BOARDS,
      {
        itemid: itemIdsArray,
        boardid: currentBoard,
      },
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    return response?.data?.data;
  } catch (error) {
    console.error("axios request:", error);
    throw error;
  }
};
