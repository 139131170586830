import { Button } from "antd";
import { useState } from "react";
import { ArrowUpOutlined } from "@ant-design/icons";
import mondaySdk from "monday-sdk-js";
import Spinner from "../../components/Spinner";

const monday = mondaySdk();

const UpdateVersion = () => {
  //create a logic for the update link

  const [load, setLoad] = useState(false);

  const update = () => {
    setLoad(true);

    //delete the user in the database
    monday
      .api(
        `query { 
        boards {
          creator {
            account {
              slug 
            }
            id
          }
        }
      }`,
        { apiVersion: "2023-10" }
      )
      .then((response) => {
        const slug = response.data.boards[0].creator.account.slug;
        const appid = "10111107";
        monday.execute("openLinkInTab", {
          url: `https://${slug}.monday.com/apps/installed_apps/${appid}`,
        });
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };

  return (
    <>
      <div className="flex h-screen">
        <div className="p-2 m-auto">
          <div className="flex items-center justify-center w-full">
            <div className="w-1/2 text-center">
              <img
                src="/img/simb-logo-small.png"
                alt="Logo"
                className="w-20 h-20 mx-auto mb-4"
              />
              <p className="font-semibold">Time to Update!</p>
              <p className="text-sm text-gray-600">
                We added new features and fixed some bugs to make your
                experience as smooth as possible.
              </p>

              <p className="text-sm text-gray-600">
                After a successful update, please close this tab and reload
                the browser.
              </p>

              {!load ? (
                <Button
                  onClick={() => update()}
                  type="primary"
                  className="mt-3"
                >
                  <ArrowUpOutlined />
                  Update App
                </Button>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateVersion;
