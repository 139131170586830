import useSWR from "swr";
import useUser from "../context/userHook";
import { MondayApiQueryInstance } from "../../services/monday/monday-service-ts";
import {
  UseWorkspaceListResponse,
  WorkspaceType,
} from "../../types/workspace.list.interface";

const useWorkspaceList = (bigAccount: boolean): UseWorkspaceListResponse => {
  const { userId } = useUser();

  const fetcher = async (): Promise<WorkspaceType[]> => {
    return MondayApiQueryInstance.getWorkspaces();
  };

  const { data, error, isLoading, isValidating, mutate } = useSWR<
    WorkspaceType[]
  >(
    userId && bigAccount ? `workspace_list:${userId}` : null, // Use userId in the key
    userId && bigAccount ? fetcher : null // Only use the fetcher if userId is available
  );

  return {
    workspaceListdata: data || [],
    error,
    isValidating,
    mutate,
    isLoading,
  };
};

export default useWorkspaceList;
