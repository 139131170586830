import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import { DropdownChevronRight } from "monday-ui-react-core/icons";
import { SettingSwitch } from "./SettingSwitch";
import useIndicator from "../../../hooks/api/Settings/useIndicator";
import useSyncDeletion from "../../../hooks/api/Settings/useSyncDeletion";
import { SettingsDrawerPropsInterface } from "./types/settings-drawer-interface";

const SettingsDrawerList = ({
  switchValues,
  setSwitchValues,
  handleCloseDrawer,
}: SettingsDrawerPropsInterface) => {
  const { updateIndicator } = useIndicator();
  const { updateSyncDeletion } = useSyncDeletion();

  return (
    <Box sx={{ width: 350 }}>
      <div onClick={() => handleCloseDrawer(false)} className="p-2 ">
        <div className="cursor-pointer flex items-center justify-between rounded hover:bg-[#6768791a] px-2 py-1">
          <h4 className="font-medium">Settings</h4>
          <DropdownChevronRight />
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-3 px-4 py-4">
        <SettingSwitch
          label="Visual indicator"
          initialValue={switchValues.visual_indicator_setting}
          setNewValue={setSwitchValues}
          settingKey="visual_indicator_setting"
          updateFunction={updateIndicator}
          tooltip="Adds an emoji to the name of all connected items"
        />
        <SettingSwitch
          label="Sync item deletion across boards"
          initialValue={switchValues.sync_deletion_setting}
          setNewValue={setSwitchValues}
          settingKey="sync_deletion_setting"
          updateFunction={updateSyncDeletion}
          tooltip="When an item is deleted on monday all items connected to it will be deleted"
        />
      </div>
    </Box>
  );
};

export default SettingsDrawerList;
