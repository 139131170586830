import simbApi from ".";
import {
  ITEM_LOCATIONS_ENDPOINT,
  DELETE_ALL,
  DELETE,
  UNLINK,
} from "../../data/constants/endpoints";

export const getItemLocations = async (access_token, itemIdsArray, currentBoard, userId) => {
  try {
    const response = await simbApi.post(ITEM_LOCATIONS_ENDPOINT, {
      itemid: itemIdsArray,
      boardid: currentBoard,
    }, { headers: { userid: userId, Authorization: `Bearer ${access_token}`}});

    return response?.data?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteAllItems = async (access_token, itemIds) => {
  try {
    const response = await simbApi.post(
      DELETE_ALL,
      { itemids: itemIds },
      { headers: { Authorization: `Bearer ${access_token}`} }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteItem = async (access_token, itemId, boardId) => {
  try {
    const response = await simbApi.post(
      DELETE,
      { itemid: itemId, boardid: boardId },
      { headers: { Authorization: `Bearer ${access_token}`} }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const unlinkItem = async (access_token, itemId, boardId) => {
  try {
    const response = await simbApi.post(
      UNLINK,
      { itemid: itemId, boardid: boardId },
      { headers: { Authorization: `Bearer ${access_token}`} }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
