import React, { useState, useEffect } from "react";

import { fetchDataGroupBoards } from "../../services/monday-service";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Board, Group, Workspace, Warning } from "monday-ui-react-core/icons";
import { Tooltip } from "monday-ui-react-core";
import { Fragment } from "react";
import Spinner from "../Spinner";
import ButtonComponent from "../Button";
import useSubitemContext from "../../hooks/context/subitemContext";
import { useList } from "../../hooks/componentHooks/listHooks";
import { useWorkspaceAndBoards } from "../../hooks/api/useWorkspacesAndBoards";

const WorkspacesAndBoardsList = ({
  searchText,
  addItem,
  itemsLoader = [],
  itemsDisabled = [],
}) => {
  const [openWorkspaces, setOpenWorkspaces] = useState({});
  const [openBoards, setOpenBoards] = useState({});
  const [boardGroups, setBoardGroups] = useState({});
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [retry, setRetry] = useState(false);
  const [reauth, setReauth] = useState(0);

  const {
    isLoading,
    error,
    workspacesAndBoards,
    mutateWorkspacesAndBoards,
    success,
    isValidating,
  } = useWorkspaceAndBoards();

  const { cleanUpDisabled } = useList();

  const { parentBoard } = useSubitemContext();

  useEffect(() => {
    // Reset retry count when the searchText changes (assuming this should reset retries on new searches)
    if (success) {
      setReauth(0);
    }
  }, [success]);

  useEffect(() => {
    if (isValidating) {
      cleanUpDisabled(workspacesAndBoards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspacesAndBoards]);

  const handleWorkspaceClick = (workspaceId) => {
    setOpenWorkspaces((prevState) => ({
      ...prevState,
      [workspaceId]: !prevState[workspaceId],
    }));
    setSelectedWorkspace(workspaceId);
  };

  const handleBoardClick = async (workspaceId, boardId) => {
    if (!(openBoards[workspaceId] && openBoards[workspaceId][boardId])) {
      const result = await fetchDataGroupBoards(boardId);

      setBoardGroups((prevGroups) => ({
        ...prevGroups,
        [boardId]: result,
      }));
    }

    setOpenBoards((prevState) => ({
      ...prevState,
      [workspaceId]: {
        ...prevState[workspaceId],
        [boardId]: !(prevState[workspaceId] && prevState[workspaceId][boardId]),
      },
    }));
  };

  /**
   * @param {{
   *  id: string,
   *  name: string,
   *  boards: Array<{
   *    id: string,
   *    name: string,
   *  }>
   * }} workspace
   */
  const filterByWorkspaceBoards = (workspace) => {
    const isFilteredByWorkspace = workspace.name
      .toLowerCase()
      .includes(searchText.toLowerCase().trim());

    if (isFilteredByWorkspace) {
      return workspace.boards;
    } else {
      return workspace.boards.filter((board) =>
        board.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
  };

  const filterByWorkspaceAndBoards = () => {
    return workspacesAndBoards.filter(
      (workspace) =>
        workspace.name.toLowerCase().includes(searchText.toLowerCase()) ||
        workspace?.boards.some((board) =>
          board.name.toLowerCase().includes(searchText.toLowerCase())
        )
    );
  };

  const FilteredWorkspacesAndBoards = searchText
    ? filterByWorkspaceAndBoards()
    : workspacesAndBoards;

  useEffect(() => {
    const newOpenWorkspaces = {};
    if (searchText.trim()) {
      // Expand only those workspaces that match the search criteria or contain boards that match.
      workspacesAndBoards.forEach((workspace) => {
        const isMatch =
          workspace.name.toLowerCase().includes(searchText.toLowerCase()) ||
          workspace.boards.some((board) =>
            board.name.toLowerCase().includes(searchText.toLowerCase())
          );
        newOpenWorkspaces[workspace.id] = isMatch; // Expand if there's a match
      });
    } else {
      // Here you can decide whether to collapse all when there's no search text
      // Or retain their previous state. For simplicity, let's collapse all.
      // workspacesAndBoards.forEach((workspace) => {
      //   newOpenWorkspaces[workspace.id] = false; // Collapse all if search is cleared
      // });
    }
    // setOpenWorkspaces(newOpenWorkspaces);
  }, [searchText, workspacesAndBoards]);

  const retryFetch = async () => {
    try {
      setRetry(true);
      setReauth(reauth + 1);
      await mutateWorkspacesAndBoards();
    } catch (error) {
      console.error(error);
    } finally {
      setRetry(false);
    }
  };

  if (isLoading || retry) {
    return (
      <>
        <div className="flex items-center gap-2 mt-2">
          <Spinner /> Getting your workspaces and boards
        </div>
      </>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center gap-2">
        {reauth >= 1 ? (
          <div className="flex items-center text-center justify-center max-w-[241px]">
            <span>
              If the error persists, try authorizing again using this{" "}
              <a
                className="hover:underline"
                rel="noreferrer"
                href="https://auth.monday.com/oauth2/authorize?client_id=9d9d91f40e511e3954b7d1df7184115e"
                target="_blank"
              >
                link
              </a>
            </span>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center">
              <Warning className="mr-1" />
              <span>An error occurred. Please try again.</span>
            </div>
          </>
        )}

        <div className="flex justify-center">
          <ButtonComponent
            text="Retry"
            className="retry-button"
            onClick={retryFetch}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
        className="!text-xs"
        component="nav"
      >
        {FilteredWorkspacesAndBoards.map((workspace) => {
          const filteredBoards = searchText.trim()
            ? filterByWorkspaceBoards(workspace)
            : workspace.boards;

          return (
            <Fragment key={workspace.id}>
              {/* Workspace List Item */}
              <ListItemButton
                sx={{
                  bgcolor: openWorkspaces[workspace.id] ? "#CCE5FF" : "inherit",
                  "&:hover": {
                    bgcolor:
                      selectedWorkspace === workspace.id
                        ? "#E6E9EF"
                        : "inherit",
                  },
                  borderRadius: "4px",
                }}
                onClick={() => {
                  handleWorkspaceClick(workspace.id);
                }}
              >
                <ListItemIcon
                  sx={{ minWidth: "unset" }}
                  className="!w-auto mr-1"
                >
                  <Workspace />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "0.875rem",
                    fontFamily: "Poppins",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  primary={`${workspace.name}`}
                />
                {openWorkspaces[workspace.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              {/* Boards and Items (Subitems) */}
              <Collapse
                in={openWorkspaces[workspace.id]}
                timeout={100}
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {filteredBoards.length > 0 ? (
                    filteredBoards.map((board) => {
                      return (
                        <Fragment key={board.id}>
                          {/* Board List Item */}
                          <Tooltip
                            content={
                              board.synced || parentBoard === board.id
                                ? "Your subItem is already shared with this board."
                                : ""
                            }
                          >
                            <ListItemButton
                              onClick={() => {
                                handleBoardClick(workspace.id, board.id);
                              }}
                              sx={{
                                pl: 4,
                                borderRadius: "4px", // Other styles here
                              }}
                              disabled={
                                (board.synced ? true : false) ||
                                parentBoard === board.id
                              }
                            >
                              <ListItemIcon
                                sx={{ minWidth: "unset" }}
                                className="!w-auto mr-1"
                              >
                                <Board />
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={{
                                  fontSize: "0.875rem",
                                  fontFamily: "Poppins",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                primary={`${board.name}`}
                              />
                              {openBoards[workspace.id] &&
                              openBoards[workspace.id][board.id] ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItemButton>
                          </Tooltip>

                          <Collapse
                            in={
                              openBoards[workspace.id] &&
                              openBoards[workspace.id][board.id]
                            }
                            timeout={100}
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {/* Board Item */}
                              {boardGroups[board.id] ? (
                                boardGroups[board.id].map((group) => (
                                  <ListItemButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      addItem(group.id, board.id);
                                    }}
                                    key={group.id}
                                    sx={{ pl: 6 }}
                                    disabled={
                                      board.synced ||
                                      itemsDisabled.some((item) =>
                                        item.endsWith(`-${board.id}`)
                                      )
                                    }
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: "unset",
                                        color: `${group.color}`,
                                      }}
                                      className="!w-auto mr-1"
                                    >
                                      <Group />
                                    </ListItemIcon>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        fontSize: "0.875rem",
                                        fontFamily: "Poppins",
                                        color: `${group.color}`,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                      primary={group.title}
                                    />

                                    {itemsLoader.includes(
                                      `${group.id}-${board.id}`
                                    ) && <Spinner size={14} />}
                                  </ListItemButton>
                                ))
                              ) : (
                                <Spinner />
                              )}
                            </List>
                          </Collapse>
                        </Fragment>
                      );
                    })
                  ) : (
                    <div>No boards match your search</div>
                  )}
                </List>
              </Collapse>
            </Fragment>
          );
        })}
      </List>
    </>
  );
};

export default WorkspacesAndBoardsList;
