import mondaySdk from "monday-sdk-js";
import { supportedColumnsNoneStr } from "../data/constants/supportedColumnsNoneStr";

const monday = new mondaySdk();

export const getBoardColumns = async (itemId) => {
  try {
    const data = await monday.api(
      `query {
        items (ids: ${parseInt(itemId)}) {
          column_values (types: ${supportedColumnsNoneStr}) {
            column {
              id
              title
            }
            id
            type
            value
            text
          }
        }
      }`,
      { apiVersion: "2023-10" }
    );

    if (!data.data.items[0]) {
      console.error(
        "No data returned from API. Board might be deleted or inactive"
      );
      return [];
    }

    return data.data.items[0]?.column_values;
  } catch (error) {
    console.error("Error getting board columns:", error);
  }
};

//this function will get the groups of the board
export const fetchDataGroupBoards = async (boardId) => {
  try {
    const response = await monday.api(
      `
            query {
                boards (ids: ${parseInt(boardId)}) {
                groups{
                    id
                    title
                    deleted
                    archived
                    color
                    } 
                }
            }`,
      { apiVersion: "2023-10" }
    );

    const filteredGroups = response?.data?.boards[0]?.groups.filter(
      (group) => !group.deleted && !group.archived
    );

    return filteredGroups;
  } catch (err) {
    console.error(err);
  }
};

export const getSlug = async () => {
  try {
    const response = await monday.api(
      `query {
          users {
            account {
              slug
            }
          }
        }`
    );

    return response?.data?.users[0]?.account.slug;
  } catch (error) {
    console.error(error);
  }
};

export const getItemNames = async (itemIds) => {
  try {
    const response = await monday.api(`query {
      items (limit: 1000, ids: ${JSON.stringify(itemIds)}) {
        name
        id
        board {
          id
        }
      }
    }`);

    return response?.data?.items;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBoardUsageDetails = async (boardId, items) => {
  const itemIds = items.reduce(
    (acc, item) => [...acc, parseInt(item.itemId)],
    []
  );

  try {
    const response = await monday.api(`query {
     boardData: boards (ids: ${parseInt(boardId)}, state: all) {
        name
        state
        permissions
      }
      itemData: items (ids: ${JSON.stringify(itemIds)}){
        id
        name
      }
    }`);

    if (response?.errors) {
      throw new Error(response.errors?.[0]?.message);
    }

    console.log(response.data);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkSubitems = async (itemIdArray) => {
  try {
    const response = await monday.api(
      ` query {
        items (ids: [${itemIdArray}]) {
          subitems{
            id
          }
      }`,
      { apiVersion: "2023-10" }
    );

    if (response?.errors) {
      throw new Error(response.errors?.[0]?.message);
    }

    return response?.data?.items;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkSubitemParentBoard = async (subitemId) => {
  try {
    const response = await monday.api(`query {
      items(ids: ${parseInt(subitemId)}) {
        parent_item {
          id
          board {
            id
          }
        }
      }
    }`);

    if (response?.errors) {
      throw new Error(response.errors?.[0]?.message);
    }

    return response?.data?.items[0]?.parent_item?.board?.id;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
