import React, { useEffect, useState } from "react";
import { Alert } from "monday-ui-react-core/icons";
import ButtonComponent from "../Button";
import mondaySdk from "monday-sdk-js";
import useDeleteItems from "../../hooks/api/useDeleteItems";
import PageLoader from "../PageLoader";

const monday = mondaySdk();

const DeleteModal = () => {
  const { deleteAllHook } = useDeleteItems();
  const [userId, setUserId] = useState();
  const [itemIds, setItemIds] = useState();
  const [loading, setLoading] = useState(false);
  const getUrlParams = (url) => {
    const searchParams = new URLSearchParams(new URL(url).search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  };

  useEffect(() => {
    const params = getUrlParams(window.location.href);

    setUserId(params["userId"]);
    setItemIds(params["itemIds"].split(","));
  }, []);

  const handleCloseModal = () => {
    monday.execute("closeAppFeatureModal").then((res) => {
      console.log(res.data);
    });
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteAllHook(userId, itemIds);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);

      monday.execute("closeAppFeatureModal").then((res) => {
        console.log(res.data);
      });
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="px-8 py-4 delete-custom-modal">
      <div className="flex items-center">
        <Alert size={32} className="mr-2 text-[#EAAA15]" />
        <h1 className="m-0 font-semibold">Delete All Items</h1>
      </div>
      <div>
        <p className="m-0 text-sm text-[#979797]">Same Item Multiple Boards</p>
      </div>
      <div className="mt-4">
        <p className="m-0">Are you sure you want to delete all items?</p>
        <p className="m-0 mt-1 ">
          This item will be removed from all shared locations, including the
          original board.
        </p>
        <p className="font-bold">
          Proceeding can result in irreversible data loss.
        </p>
      </div>
      <div className="flex justify-end gap-2">
        <ButtonComponent
          onClick={handleCloseModal}
          kind="tertiary"
          text="Cancel"
        />
        <ButtonComponent
          onClick={handleDelete}
          kind="primary"
          color="negative"
          text="Delete"
        />
      </div>
    </div>
  );
};

export default DeleteModal;
