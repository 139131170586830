import React from "react";
import { Row, Space, Typography } from "antd";
import Navbar from "../Navbar/Navbar";
import AddItemButton from "../addItem/AddItemButton";
const { Title } = Typography;

const EmptyLocations = () => {
  return (
    <Row
      style={{
        backgroundColor: "#ffffff",
      }}
      className="relative flex flex-col items-center w-full h-screen px-12"
    >
      <Navbar />
      <Space className="flex flex-col mb-[1.125rem]">
        <Title level={2} className="text-center max-w-[25rem]">
          Your item doesn't live on other boards yet!
        </Title>
      </Space>
      <Space>
        <AddItemButton centered={true} fullWidth={false} />
      </Space>
    </Row>
  );
};

export default EmptyLocations;
