import simbApi from ".";
import {
  UPDATE_SHARED_SUBITEMS_SETTINGS,
  GET_SHARED_SUBITEMS_SETTINGS,
} from "../../data/constants/endpoints";

export const getSharedSubitemsSettings = async (access_token, itemIds) => {
  try {
    const response = await simbApi.post(
      GET_SHARED_SUBITEMS_SETTINGS,
      {
        itemid: itemIds[0],
      },
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    return response.data?.data?.sharedSubItem;
  } catch (error) {
    console.error("Error getting shared subitems settings:", error);
    throw error;
  }
};

export const updateSharedSubitemsSettings = async (
  access_token,
  activeItems,
  checked
) => {
  try {
    const response = await simbApi.post(
      UPDATE_SHARED_SUBITEMS_SETTINGS,
      { itemid: activeItems[0], sharedSettings: checked },
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    return response?.data?.data;
  } catch (error) {
    console.error("Error updating subitem settings:", error);
    throw error;
  }
};
