// src/hooks/useList.js

import { useSelector, useDispatch } from "react-redux";
import {
  setItemLoading,
  removeItemLoading,
  setItemSelected,
  removeItemSelected,
  setItemDisabled,
  removeItemDisabled,
  cleanUpItemDisabled,
} from "../../redux/slices/listSlice"; // Adjust the import path as necessary

export function useList() {
  const dispatch = useDispatch();
  const { isLoading, isSelected, isDisabled } = useSelector(
    (state) => state.list
  );

  const isItemLoading = (item) => isLoading.includes(item);
  const isItemSelected = (item) => isSelected.includes(item);

  const setItemLoadingAction = (item) => dispatch(setItemLoading(item));
  const removeItemLoadingAction = (item) => dispatch(removeItemLoading(item));
  const setItemSelectedAction = (item) => dispatch(setItemSelected(item));
  const removeItemSelectedAction = (item) => dispatch(removeItemSelected(item));
  const setItemDisabledAction = (item) => dispatch(setItemDisabled(item));
  const removeItemDisabledAction = (item) => dispatch(removeItemDisabled(item));
  const cleanUpDisabledBoardsAction = (item) =>
    dispatch(cleanUpItemDisabled(item));

  const cleanUpDisabled = async (boards) => {
    try {
      await Promise.all(
        boards
          .filter((board) => !board.synced)
          .map((board) => cleanUpDisabledBoardsAction(board.id))
      );
    } catch (error) {
      throw error;
    }
  };

  return {
    isLoading,
    isSelected,
    isDisabled,
    isItemSelected,
    isItemLoading,
    setItemSelected: setItemSelectedAction,
    setItemLoading: setItemLoadingAction,
    removeItemSelected: removeItemSelectedAction,
    removeItemLoading: removeItemLoadingAction,
    setItemDisabled: setItemDisabledAction,
    removeItemDisabled: removeItemDisabledAction,
    cleanUpDisabled,
  };
}
