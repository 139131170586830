import { useState } from "react";

type ElementType = HTMLElement | React.RefObject<HTMLElement>;

function useCalcHeight(maxHeight: number = 400) {
  const [height, setHeight] = useState<number>(maxHeight);

  const calcHeight = (ref: ElementType) => {
    let element: HTMLElement | null = null;
    if (ref instanceof HTMLElement) {
      element = ref;
    } else if (ref && "current" in ref) {
      element = ref.current;
    }

    if (element) {
      const contentHeight = element.offsetHeight;
      setHeight(contentHeight > maxHeight ? maxHeight : contentHeight + 20);
    }
  };

  return {
    height,
    setHeight,
    calcHeight,
  };
}

export default useCalcHeight;
