/* eslint-disable no-lone-blocks */
import React from "react";
import "monday-ui-react-core/dist/main.css";

import ItemsOnBoard from "./ItemsOnBoardComponent/ItemsOnBoard.js";

const HomeSection = ({ authorized }) => {
  return (
    <div className="px-6 pt-8 pb-4">
      <ItemsOnBoard authorized={authorized} />

      {/* <div className="bg-[#FFFFFF] h-screen">
      
      </div> */}
    </div>
  );
};

export default HomeSection;
