import React from "react";
import Usage from "../Usage/Usage";
import SettingsDrawer from "./Settings/SettingsDrawer";

const Navbar = () => {
  return (
    <div className="flex justify-end w-full gap-2">
      <Usage />
      <SettingsDrawer />
    </div>
  );
};

export default Navbar;
