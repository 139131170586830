import { useState, useEffect } from "react";
import { checkSubitemParentBoard } from "../../services/monday-service";
import useBoardAndItems from "./useBoardAndItems";

const useSubitemContext = () => {
  const { itemIds } = useBoardAndItems();
  const [parentBoard, setParentBoard] = useState(null);

  useEffect(() => {
    const fetchParentBoard = async () => {
      if (itemIds) {
        const parentBoard = await checkSubitemParentBoard(itemIds);
        setParentBoard(parentBoard);
      }
    };

    fetchParentBoard();
  }, [itemIds]);

  return {
    parentBoard,
  };
};

export default useSubitemContext;
