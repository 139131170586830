import { useSelector } from "react-redux";

const useUser = () => {
  const { userId, accountId } = useSelector((state) => state.monday);

  return {
    userId,
    accountId,
  };
};

export default useUser;
