import { Fragment, useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { WorkspaceType } from "../../types/workspace.list.interface";
import { Workspace } from "monday-ui-react-core/icons";
import { Search } from "monday-ui-react-core";
import useSearchList from "../../hooks/componentHooks/useSearchList";
import { CSSTransition } from "react-transition-group";
import BoardsList from "./BoardsList";

interface WorkspaceListProps {
  data: WorkspaceType[] | [];
  calcHeight: (el: any) => void;
}

const WorkspaceList = ({ data, calcHeight }: WorkspaceListProps) => {
  const [search, setSearch] = useState("");
  const [activeMenu, setActiveMenu] = useState("main");
  const filteredItems = useSearchList(data, search, "name");

  return (
    <div className="relative w-full">
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <div
            style={{ padding: "15px 8px 12px 8px" }}
            className="bg-[#FFFFFF] sticky top-0 z-[1] border-b border-[#C3C6D4] overflow-x-hidden"
          >
            <Search
              placeholder="Search Workspaces"
              onChange={(e) => {
                setSearch(e);
              }}
              size={"small"}
              value={search}
            />
          </div>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
            className="!text-xs"
            component="nav"
          >
            {data.length > 0 &&
              filteredItems.map((workspace) => {
                return (
                  <Fragment key={workspace.id}>
                    <ListItemButton
                      sx={{
                        "&:hover": {
                          bgcolor: "#E6E9EF",
                        },
                        borderRadius: "4px",
                      }}
                      onClick={() => {
                        setActiveMenu(workspace.id);
                        setSearch("");
                      }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "unset" }}
                        className="!w-auto mr-1"
                      >
                        <Workspace />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: "0.875rem",
                          fontFamily: "Poppins",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        primary={`${workspace.name}`}
                      />
                    </ListItemButton>
                  </Fragment>
                );
              })}
          </List>
        </div>
      </CSSTransition>

      {data.length > 0 &&
        data.map((workspace) => {
          return (
            <CSSTransition
              in={activeMenu === workspace.id}
              unmountOnExit
              timeout={500}
              classNames="menu-secondary"
              key={workspace.id}
              onEnter={calcHeight}
            >
              <div className="menu">
                <BoardsList
                  workspaceName={workspace.name}
                  workspaceId={workspace.id}
                  setActiveMenu={setActiveMenu}
                  calcHeight={calcHeight}
                />
              </div>
            </CSSTransition>
          );
        })}
    </div>
  );
};

export default WorkspaceList;
