import useUser from "../../context/userHook";
import { visualIndicator } from "../../../utils/requests/settingsRequest";
import { useState } from "react";
// import { VisualIndicatorServiceImpl } from "../../../services/visual-indicator-service/visual-indicator-service";
import debounce from "lodash/debounce";
import useTokenHook from "../../context/useTokenHook";

const useIndicator = () => {
  const { accountId } = useUser();
  const { token } = useTokenHook();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const debouncedUpdateIndicator = debounce(async (setting) => {
    setLoading(true);
    setError(null);
    try {
      const response = await visualIndicator(token, accountId, setting);

      // if (response.data.data && response.data.data.length > 0) {
      //   await VisualIndicatorServiceImpl.setVisualIndicator(
      //     setting,
      //     response.data.data
      //   );
      // }

      setData(response.data.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, 500); // Adjust the debounce delay as needed

  const updateIndicator = (setting) => {
    debouncedUpdateIndicator(setting);
  };

  return {
    visualIndicatorSetting: data,
    error,
    loading,
    updateIndicator,
  };
};

export default useIndicator;
