import React, { useState } from "react";
import { Typography } from "antd";
import ButtonComponent from "./Button";
import PageLoader from "./PageLoader";

const { Title, Paragraph } = Typography;

const ErrorPage = ({ revalidate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleRetry = async () => {
    try {
      setIsLoading(true);
      revalidate ? await revalidate() : window.location.reload();

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="flex flex-col items-center justify-center text-center App xl:gap-y-5 ">
      <img src="./img/errorImage.svg" alt="" className="w-1/3 h-1/3" />
      <Title className="w-full pb-0 mt-2" level={5}>
        Something went wrong
      </Title>
      <ButtonComponent
        className="mt-2 mb-2"
        text={"Retry"}
        onClick={handleRetry}
      />
      <Paragraph className="w-full ">
        Please send us a message at support@pioneera.io
      </Paragraph>
    </div>
  );
};

export default ErrorPage;
